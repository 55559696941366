<template>
  <div>
    <div v-if="error">{{ error }}</div>
    <v-card v-if="playlist">
      <v-img :src="playlist.coverUrl" height="50vh">
        <div height="100%" class="d-flex flex-column align-end mt-auto">
          <v-card-title class="white--text text-h4">
            {{ playlist.title }}
            <delete-dialog
              v-if="ownership"
              color="white"
              :itemName="playlist.title"
              @handleDelete="handleDelete"
            />
          </v-card-title>
          <v-card-subtitle class="white--text text-subtitle">
            {{ playlist.description }}
          </v-card-subtitle>
          <v-card-subtitle class="white--text text-caption">
            {{ playlist.userName ? ` by ${playlist.userName}` : '' }}
          </v-card-subtitle>
        </div>
      </v-img>
      <add-song-form v-if="ownership" class="pa-1 my-2" :playlist="playlist" />
      <v-divider></v-divider>
      <div v-if="!playlist.songs.length">
        Nenhuma música adicionada nesta playlist
      </div>
      <div v-else>
        <songs :playlist="playlist" :ownership="ownership" />
      </div>
    </v-card>
  </div>
</template>

<script>
import { useStorage, useDocument, getDocument } from '@/composables'
import { computed, ref } from '@vue/composition-api'
import store from '@/store'
import utils from '@/utils/utils'
import router from '@/router'
import { AddSongForm, Songs } from '../components'
import { DeleteDialog } from '@/components/layout/dialogs'
export default {
  name: 'PaylistDetails',

  components: {
    AddSongForm,
    DeleteDialog,
    Songs
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const songIndex = ref(-1)
    const loggedUser = computed(() => store.state.auth.loggedUser)
    const { error, document: playlist } = getDocument('playlists', props.id)
    const { deleteImage } = useStorage()
    const { deleteDoc, isPending } = useDocument('playlists', props.id)

    const handleDelete = async () => {
      store.dispatch('home/setOverlayLoading', true)
      await utils.delay()
      await deleteImage(playlist.value.filePath)
      await deleteDoc()
      store.dispatch('home/setOverlayLoading', false)
      router.push({ name: 'HomePlaylist' })
    }

    const ownership = computed(() => {
      return (
        playlist.value &&
        loggedUser.value &&
        loggedUser.value.uid === playlist.value.userId
      )
    })

    return {
      error,
      playlist,
      songIndex,
      isPending,
      ownership,
      handleDelete
    }
  }
}
</script>

<style></style>
